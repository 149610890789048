import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomText, PrimaryCta } from "../../common/core_component";
import { useSnackbar } from "notistack";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import ReactPullToRefresh from "react-pull-to-refresh";

function BidSuccess() {
  const navigate = useNavigate();

  const [downRefesh, setDownRefresh ] = useState(false);


  const navigateToHomeScreen = () => {
    setTimeout(() => {
      navigate("/campaigns");
    }, 800);
  };

  const handleRefresh = useCallback(async () => {
    setDownRefresh(true);  

    await new Promise((resolve) => setTimeout(resolve, 2000));    
    setDownRefresh(false);
  }, []);

  return (
    
    <div
      className=" relative container flex align-center justify-center h-90">
      <div>
        <div className="m-b-15 flex align-center justify-center">
          <Icon icon = {svgs.round_check_mark} width={100} height={100} />
        </div>

        <div className="flex align-center justify-center flex-column ">
        
          <h2 className="m-t-4 text-align"> Your proposal submitted successfully </h2>
          <div className="text-align m-t-15">
            <CustomText
              p
              text={
                " Brand representative will review your proposal, once proposal approved you will see the contract in contracts section"
              }
            />
          </div>
        </div>
        <div className="flex justify-center align-center">
          <PrimaryCta
            className={"custom-button-bottom btn-bottom"}
            onClick={navigateToHomeScreen}
            text={"Screen more Campaigns"}
          />
        </div>
      </div>
    </div>
  );
}

export default BidSuccess;
