import React, { useState } from "react";
import {
  CustomInputBox,
  CustomText,
  CustomTextArea,
  PrimaryCta,
} from "../../common/core_component";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Sdk from "../../common/sdk/influencerSdk";
import * as Action from "../../redux/actions";
import { setLocalUserData } from "../../common/storage/storage";
import { Loader } from "../Loader";
import Select from "react-dropdown-select";
import { CITIES, LANGUAGE, LOCATIONS } from "../../common/data/influencer";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import Svgs from "../../assets/icons/svgs";


function EditProfile() {
  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  // const { categorys_data = {} } = userData;
  // console.log("cat", categorys_data);
  console.log("influn", influencer_data);

  const {
    influencer_id,
    name,
    email = "",
    phone,
    language,
    state,
    note,
    legal_name,
    gender,
    city_id,
    state_id,
  } = influencer_data;

  const [eName, setName] = useState(name);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [eEmail, setEmail] = useState(email);
  const [legalName, setLegalName] = useState(legal_name || name);
  const [ePhone, setPhone] = useState(String(phone));
  const [eGender, setGender] = useState(gender);
  const [eNote, setNote] = useState(note);
  const [eLanguage, setLanguage] = useState(language);
  const [city, setCity] = useState(getInfluencerLocation(influencer_data));  
  const [userState, setUserState] = useState();
  const [eLocation, setLocation] = useState(state);
  const [updateCategory, setUpdateCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function updateProgress(status) {
    setLoading(status);
  }
  const location = useLocation();
  
  const fromState = location.state?.from;
  console.log("from state", fromState);

  const { enqueueSnackbar } = useSnackbar();

  // -------- Api Call ----------

  const logHandler = () => {
    if (!validateForm()) {
      return false;
    }
    console.log(
      "dataa",
      legalName,
      eName,
      eEmail,
      eGender,
      eLanguage,
      city,
      eNote
    );
  };

  function updateInfluencerData() {
    if (!validateForm()) {
      return false;
    }
    var params = {
      influencer_id: influencer_id,
      language: eLanguage,
      name: eName,
      email: eEmail,
      legal_name: legalName,
      // phone: ePhone,
      gender: eGender,
      // state: eLocation,
      city_id: city,
      state_id: userState,
      note: eNote,
    };

    Sdk.updateInfluencerData(
      updateInfluencerDataSuccess,
      updateInfluencerDataFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function updateInfluencerDataSuccess(response) {
    dispatch({
      type: Action.UPDATE_USER_DATA,
      payload: response.influencer_deep_data,
    });
    setLocalUserData(response); // Save data in async storage

    // if (props.route.params) {
    //   props.route.params.refreshUserData();
    // }
    
    // props.navigation.navigate('AccountSettings');
    if(fromState){
      navigate(-1);
    }else{
      navigate('/profile')
    }
    enqueueSnackbar("Profile Edited",{variant : "success"});
  }

  function updateInfluencerDataFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function validateForm() {
    let isValid = true;
    let newErrors = {
      fullName: "",
      email: "",
      phone: "",
      gender: "",
      bio: "",
      language: "",
      city: "",
    };

    if (!eName || !eName.trim()) {
      newErrors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!eEmail || !eEmail.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(eEmail)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!ePhone || !ePhone.trim()) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    }

    if (!eGender) {
      newErrors.gender = "Gender is required";
      isValid = false;
    }

    if (!eNote || !eNote.trim()) {
      newErrors.bio = "Bio is required";
      isValid = false;
    }

    if (!city) {
      newErrors.city = "City is required";
      isValid = false;
    }
    if (!language) {
      newErrors.city = "Language is required";
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  }

  // ------ UI Rendering ------

  function renderInputBoxes() {
    return (
      <>
        {/* Full Name  */}
        <div className="m-t-12">
          <CustomText p text="Full Name" className= "m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={eName}
              onChange={(e) => setName(e.target.value)}
              placeholder={influencer_data?.name}
            />
          </div>
          {error.fullName && <p className="error">{error.fullName}</p>}
        </div>
        {/* legal Name  */}
        <div className="m-t-12">
          <CustomText p text="Legal Name" className= "m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={legalName}
              onChange={(e) => setLegalName(e.target.value)}
              placeholder={influencer_data?.legal_name}
            />
          </div>
          {/* {error.legalName && <p className="error">{error.legalName}</p>} */}
        </div>

        {/* Email */}
        <div className="m-t-12">
          <CustomText p text="Email"  className= "m-b-5"/>
          <div className="">
            <CustomInputBox
              type="email"
              value={eEmail}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={email}
            />
          </div>
          {error.email && <p className="error">{error.email}</p>}
        </div>
      </>
    );
  }

  // ----- gender Options
  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "Other", label: "Other"},
  ];

  function updateGender(selectedOptions) {
    const selectedValue =
      selectedOptions && selectedOptions.length > 0
        ? selectedOptions[0].value
        : null;
    setGender(selectedValue);
  }

  function renderGender() {
    return (
      <>
        <div className="m-t-12">
          <p className="font-bold m-b-5">Gender</p>
          <div className="m-t-4 " >
            <Select
              multi={false}
              options={options}
              value={options.find((option) => option.value === gender) || null}
              onChange={updateGender}
              placeholder={eGender || "Select Gender"}
              autoFocus={false}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #A6A5BB",
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  fontFamily: "Inter-Medium",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }),
              }}
            />
          </div>

          {error.gender && <p className="error">{error.gender}</p>}
        </div>
      </>
    );
  }

  //  ------- Handling Selection Boxes -------


  const handleSelectionLanguage = (selectedOption) => {
    console.log("select", selectedOption);

    if (selectedOption && selectedOption.length > 0) {
        setLanguage(selectedOption[0].value);
        
    } else {
        setLanguage(null);
        // console.log("null language", eLanguage);
        
    }
};

  const handleSelectionCity = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      // const selectedLabel = selectedOption[0].id;
      const selectedLabel = selectedOption[0].value;
      const selectStateId = selectedOption[0]?.state_id;
      setCity(selectedLabel);
      setUserState(selectStateId)
    }
  };


  function getInfluencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CITIES.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

 console.log("city " , getInfluencerLocation(influencer_data));
 
  function renderCity() {
    return (
      <>
        <div className="m-t-12">
          <CustomText p text={"City"} className= "m-b-5" />
          <div className="m-t-4">
            <Select
              isMulti={false}
              options={CITIES.filter((city) => city.type === "city").map((city) => ({
                value: city.id,
                label: city.label,
                state_id: city.state_id,
              }))}
              value={
                city
                  ? {
                      value: city,
                      label: CITIES.find((c) => c.id === city)?.label,
                    }
                  : null
              }
              onChange={(selectedOption) => handleSelectionCity(selectedOption)}
              placeholder={city ||"Enter City"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #A6A5BB",
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  fontFamily: "Inter-Medium",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#a6a5bb",
                  },
                }),
              }}
            />
          </div>
          {error.city && <p className="error">{error.city}</p>}
        </div>
      </>
    );
  }

  function renderLanguage(){
    return (
        <>
            <div className="m-t-12">
                <CustomText p text={"Language"} className="m-b-5" />
                <div className="">
                <Select
    isMulti={false}
    options={Object.values(LANGUAGE).map((lang) => ({
        value: lang.value,
        label: lang.label,
    }))}
    value={
        eLanguage
            ? { value: eLanguage, label: LANGUAGE[eLanguage]?.label }
            : []
    }
    onChange={handleSelectionLanguage}
    placeholder= { eLanguage ||"Select a language"}
    styles={{
        control: (base) => ({
            ...base,
            border: "1px solid #A6A5BB",
            backgroundColor: "white",
            paddingBottom: "10px",
            paddingTop: "10px",
            borderRadius: "",
            fontFamily: "Inter-Medium",
        }),
        option: (base) => ({
            ...base,
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "#e0e0e0",
            },
        }),
    }}
/>

                </div>
                {error.language && <p className="error">{error.language}</p>}
            </div>
        </>
    );
}

  function renderBio() {
    return (
      <>
        <div className="m-t-12">
          <p className="font-bold m-b-5">Bio</p>
          <div className="">
            <CustomTextArea
              value={eNote}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Tell us About your self."
            />
          </div>
          {error.bio && <p className="error">{error.bio}</p>}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="header-fixed flex justiify-center gap-10">
      <div className="cursor-pointer p-t-3 " onClick={ ()=> navigate('/profile')} style={{paddingLeft : "10px"}}>
            <Icon icon={Svgs.back_black_icon} width={13} height={13} />
          </div>
        <CustomText p text={"Edit Profile"} />
      </div>

      {loading || Object.keys(influencer_data).length === 0 ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div
          className="container m-b-75"
          style={{
            paddingTop: "58px",
            marginBottom: "120px",
          }}
        >
          {/*-- input bixes -- */}
          {renderInputBoxes()}
          {renderGender()}
          {renderCity()}
          {renderLanguage()}
          {renderBio()}

          <div className="btn-container-details-page">
            <PrimaryCta
              className={"btn-bottom-detail-page"}
              // onClick={logHandler}
              onClick={updateInfluencerData}
              isLoading = {effectiveLoad}
              text={"Save"}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default EditProfile;
