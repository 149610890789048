// Dependencies
import React, { useState, useEffect, Suspense } from "react";

// Import Redux State (Private scope)
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

// Navigation
import RootNavigator from "./navigation/root_navigation";

import * as Actions from "./redux/actions";

// Core Sdk (Private Scope)
import Sdk from './common/sdk/influencerSdk.js';

import Utils from "./common/utils.js";
import WebStorage from "./common/storage/storage";
import {
  USER_DATA
} from "./common/storage/keys";

import * as Action from '../src/redux/actions.js'
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = function (props) {

  var navigate = useNavigate();

  const [appLoaded, setAppLoaded] = useState(false);
  const [localUserData , setLocalUserData] = useState("")



  if (process.env.NODE_ENV == "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }

  // Initialize Private Functions
  var redux_dispatch = useDispatch();

useEffect(()=>{
refreshUserData()
},[])



  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    setAppLoaded(status);
  }

  function refreshUserData() {
    const params = {};

    Sdk.getInfluencerDataSelf(
      refreshUserDataSuccess,
      refreshUserDataFailed,
      () => {},
      params,
      // reduxData.UserData.authToken,
    );
  }

  function refreshUserDataSuccess(userData, authToken) {
    setLocalUserData(userData);

    if (userData) {
      const {influencer_deep_data} = userData;
      redux_dispatch({
        type: Action.UPDATE_USER_DATA,
        payload: influencer_deep_data,
      });
      navigate('/')
    }
  }

  function refreshUserDataFailed(error_list) {
    // navigate('/')
    // if(Utils.influencerLogined_Or_Not)
    // setSponsorMeLoading(false);

    // showError(error_list.map(err => err['m']).join(', '));

    // setVisible(false);
  }


  // Return App
  return (
    
    <>
      {/* <GoogleOAuthProvider clientId="812102543926-2678dtjli58h3gbohba5g6tmb1fhu2fu.apps.googleusercontent.com"> */}
      
    <div className=" app-container">
      <RootNavigator />
    </div>

      {/* </GoogleOAuthProvider> */}
    </>
       
  );
};

export default App;