import React, { useState } from "react";
import Select from "react-dropdown-select";
import { CITIES, LANGUAGE, LOCATIONS } from "../common/data/influencer";
import { CustomButton, PrimaryCta } from "../common/core_component/index.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Action from "../redux/actions.js";
import Sdk from "../common/sdk/influencerSdk.js";
import { useSnackbar } from "notistack";
import svgs from "../assets/icons/svgs.js";
import Icon from "../assets/icons.js";

function AboutWorkData() {
  const [location, setLocation] = useState(null);
  const [language, setLanguage] = useState(null);
  const [loading, setLoading] = useState();
  const [city, setCity] = useState("");
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const returnlocation = useLocation();
  const {returnUrl = "/" } = returnlocation.state || {};

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("influn", influencer_data);

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectionLanguage = (selectedOption) => {
    console.log("select", selectedOption);

    if (selectedOption && selectedOption.length > 0) {
        setLanguage(selectedOption[0].value);
     
        
    } else {
        setLanguage(null);
    }
};

  const handleSelectionCity = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      const selectedLabel = selectedOption[0].value;
      setCity(selectedLabel);
    }
  };

  function updateProgress(status) {
    setLoading(status);
  }


  function updateInfluencerData() {
    if (!language) {
      enqueueSnackbar("Please select a language." , {variant : "error"});
      return; 
    }
    if (!city) {
      enqueueSnackbar("Please select a city.", {variant : "error"});
      return;
    }
    var params = {
      influencer_id: influencer_data?.influencer_id,
      language: language,
      // state_id: userState,
      city_id: JSON.stringify(city),
    };
    Sdk.updateInfluencerData(
      updateInfluencerDataSuccess,
      updateInfluencerDataFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function updateInfluencerDataSuccess(response) {
    const { influencer_deep_data } = response;

    dispatch({
      type: Action.UPDATE_USER_DATA,
      payload: influencer_deep_data,
    });
      // navigate("/thankyou-screen");
      navigate(-2);
  }

  function updateInfluencerDataFailed(error_list) {
    console.error("error", error_list);
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }
  return (
    <div className="m-t-15 ">
      <div className="container">

        <div className="flex justify-between m-b-8" style={{ gap: "8px" }}>
          <h5 className="">Step 2</h5>
          <div
            className="cursor-pointer"
            onClick={() => navigate(-2)}
          >
            <Icon icon={svgs.cross_icon} width={13} height={13} />
          </div>
        </div>

        <div>
          <h2>General Information</h2>
        </div>
        <div className=" m-t-8 ">
          <div className="bar2"></div>
        </div>
        {/* ----- selection Box ---------  */}
        <div className="m-t-12">
          <p className="font-bold"> Language </p>
          <div className="m-t-4">
            <Select
              isMulti={false}
              options={Object.values(LANGUAGE).map((lang) => ({
                value: lang.value,
                label: lang.label,
              }))}
              value={
                language && LANGUAGE[language]
                  ? { value: language, label: LANGUAGE[language].label }
                  : []
              }
              onChange={(selectedOption) =>
                handleSelectionLanguage(selectedOption)
              }
              placeholder={"Enter Language"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #A6A5BB",
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  fontFamily: "Inter-Medium",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }),
              }}
            />
          </div>
        </div>
        <div className="m-t-12">
          <p className="font-bold"> City </p>
          <div className="m-t-4">
            <Select
              isMulti={false}
              options={CITIES.filter((city) => city.type === "city").map((city) => ({
                value: city.id,
                label: city.label,
                state_id: city.state_id,
              }))}
              value={
                city
                  ? {
                      value: city,
                      label: CITIES.find((c) => c.id === city)?.label,
                    }
                  : null
              }
              onChange={(selectedOption) => handleSelectionCity(selectedOption)}
              placeholder={"Enter City"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #A6A5BB",
                  borderRadius : "6px" , 
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  fontFamily: "Inter-Medium",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }),
              }}
            />
          </div>
          <div className="fixed-button-container">
            <PrimaryCta
              className={"custom-button-bottom"}
              onClick={updateInfluencerData}
              text={"Save"}
              isLoading = {effectiveLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutWorkData;
